import "./aboutMe.css";
import { Typewriter } from "react-simple-typewriter";
import {motion} from "framer-motion";

const AboutMe = () => {
  return (
    <>
      <section id="aboutMe" className="flex_p">
        <motion.div 
        className="left_about column_flex_p"
        initial={{x:-30, opacity:0}}
        whileInView={{x:0, opacity:1}}
        transition={{duration:1}}
        >
          <div className="flipCard">
            <div className="aboutMeLogo column_flex_p">
              <img src="../Images/logo.png" alt="" />
            </div>
            <a href="mailto:jkeerthi144@gmail.com" download="Keerthika_Jeyandran" className="hireMe flex_p">
            <h1>Hire Me</h1>
            </a>
          </div>
        </motion.div>
        <motion.div 
        className="right_about column_flex_p"
        initial={{x:30}}
        whileInView={{x:0}}
        transition={{duration:1}}
        >
          <h1> About Me</h1>
          <h2>
            I'm Keerthika Jeyandran{" "}
            <span>
              {" "}
              and I'm a
              <Typewriter
                words={[" Graphic Designer", " Web developer", " Freelancer"]}
                cursor
                cursorStyle="|"
                loop
                typeSpeed={60}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span>
          </h2>
          <p>
            Looking for a challenging role in a reputable organization to
            utilize my technical and management skills for the growth of the
            organization as well as to enhance my knowledge about new and
            emerging trends in the IT sector.
          </p>
          <a href="../CV/JKeerthika.pdf" download="Keerthika_Jeyandran" className="resume">
            Profile
          </a>
        </motion.div>
      </section>
    </>
  );
};

export default AboutMe;
