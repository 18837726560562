import Home from "./components/Home/Home";
import Nav from "./components/Nav/nav";
import AboutMe from "./components/aboutMe/aboutMe";
import Services from "./components/Services/service";
import ContactMe from "./components/Contact/contact";
import './App.css'

const App = () => {
  return (
    <>
        <Nav/>
        <Home/>
        <AboutMe/>
        <Services/>
        <ContactMe/>
    </>
  );
}


export default App;
