import React from "react";
import "./Home.css";
import { Typewriter } from "react-simple-typewriter";

const Home = () => {
  return (
    <>
      <section id="Home" className="column_flex_p">
          <h1> Hello Guys! <br/> <span>I'm Keerthika Jeyandran</span></h1>
          <h2> <Typewriter words={["Graphic Designer", "Web Developer", "Freelancer"]} loop cursor cursorStyle="|" typeSpeed={60} deleteSpeed={50} delaySpeed={1000} /> </h2>
          <div className="icons flex_p">
              <a href="https://github.com/Keerthika04"> <img src="../Images/Git.png" width={40} alt=""/> </a>
              <a href="https://www.linkedin.com/in/keerthika-jeyandran06"> <img src="../Images/linkedIn.png" width={40} alt=""/> </a>
              <a href="https://www.instagram.com/kj_crea.tions/"> <img src="../Images/Insta.png" width={40} alt=""/> </a>
          </div>
      </section>
    </>
  );
};

export default Home;
