import React from "react";
import "./service.css";
import Cards from "./Cards/Cards"

const Services = () => {
  return (
    <>
      <section id="services" className="column_flex_p">
        <div className="bg column_flex_p">
          <h1>Services</h1>
          <div className="services flex_p">
            <Cards
            icon = "./Images/brush.png"
            title = "Web Design"
            details = "Crafting captivating web experiences that blend creativity and functionality, turning ideas into digital reality"
            />
            
            <Cards
            icon = "./Images/editing.png"
            title = "Photo Editing"
            details = "Elevating moments through artistic photo editing, capturing memories with a creative touch"
            />

            <Cards
            icon = "./Images/writing.png"
            title = "Content Writing"
            details = "Delivering captivating narratives that leave a lasting impression, I bring stories to life through compelling content"
            />

          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
