import "./Cards.css";
import { motion } from "framer-motion";

const Cards = ({ icon, title, details }) => {
  return (
    <>
      <div className="cards column_flex_p">
        <motion.div
          className="card column_flex_p"
          initial={{ scale: 0.8 }}
          whileInView={{ scale: 1 }}
          transition={{ duration: 1, type: "spring" }}
        >
          <div className="service_icon flex_p">
            <img src={icon} alt="" />
          </div>
          <h2>{title}</h2>
          <p>{details}</p>
        </motion.div>
      </div>
    </>
  );
};

export default Cards;
