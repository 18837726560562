import "./nav.css";

const Nav = () => {
  window.addEventListener("scroll", () => {
    const nav = document.querySelector(".navbar");
    nav.classList.toggle("scrolled", window.scrollY > 0);
  });

  return (
    <>
      <nav className="navbar">
        <div className="left_nav">
          <h1>Portfolio. </h1>
        </div>
        <div className="right_nav">
          <a href="#Home"> Home </a>
          <a href="#aboutMe"> About </a>
          <a href="#services"> Services </a>
          <a href="#contact"> Contact </a>
        </div>
      </nav>
    </>
  );
};

export default Nav;
