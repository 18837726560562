import "./contact.css";
import emailjs from '@emailjs/browser';
import {useRef} from "react";
import { useState } from "react";
import {motion} from "framer-motion"

const ContactMe = () => {

  const form = useRef();

  const [done, setDone] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_3k8vwfh', 'template_kkrsb0e', form.current, '412cyVW6gv26g9NB9')
      .then((result) => {
          setDone(true);
          document.getElementById("mail_form").reset();
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <>
      <section id="contact" className="column_flex_p">
        <h1>Get in Touch</h1>
        <motion.div 
        className="contact"
        initial={{scale:0.8}}
        whileInView={{scale:1}}
        transition={{duration:0.5}}
        >
          <div className="left_contact flex_p">
            <img src="../Images/QR.png" alt="" />
          </div>
          <div className="line"></div>
          <div className="right_contact">
              <form id="mail_form" ref={form} onSubmit={sendEmail}>
                <label for="name">Name</label>
                <input type="text" name="user_name" id="name" autoComplete="off" required/>
                <label for="email">Email</label>
                <input type="email" name="user_email" id="email" autoComplete="off" required/>
                <label for="msg">Message</label>
                <textarea type="text" name="message" id="msg" autoComplete="off" required/>
                <button type="submit">Send</button>
              </form>
          </div>
        </motion.div>
        <span>{done && "Your message has been sent!"}</span>
        <footer>
          <h3>Created by KJ | &copy; 2023 All rights reserved</h3>
        </footer>
      </section>
    </>
  );
};

export default ContactMe;

